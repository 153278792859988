export type EloomiSchema = {
    title: string;
    version: string;
    schema: string;
}

const schemas: EloomiSchema[] = [
    {
        title: "eloomi API v1",
        version: "1.0",
        schema: "schemas/schema-1.0.json"
    }
];


export default schemas;