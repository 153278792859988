import "./SchemaSelector.scss";
import { EloomiSchema } from "../resources/schemas";
import React, { useEffect, useState } from "react";
import schemas from "../resources/schemas"
import { CommonProps } from "../CommonProps";

export type ISchemaSelectorProps = {
    onChange: (schema: EloomiSchema) => void;
    selectedOption?: EloomiSchema;
    className?: string
}

const SchemaSelector: React.FC<ISchemaSelectorProps> = (props) => {

    useEffect(() => {
        props.onChange(schemas[0]);
    }, [])

    const onSelectSchema = (item: React.SyntheticEvent<HTMLSelectElement, Event>) => {
        const value = item.currentTarget.value;

        const schema = schemas.filter(x => x.version === value)[0];

        props.onChange(schema);
    };

    return <select className={"schema-selector-component"} onChange={onSelectSchema}>

        {
            schemas.map((s) => <option selected={s.version === props?.selectedOption?.version} value={s.version}>{s.title}</option>)
        }

    </select>;
}

export default SchemaSelector;