import './App.scss';
import { RedocStandalone } from 'redoc';
import { useEffect, useState } from 'react';
import apiDescription from '!!raw-loader!./resources/descriptions/api-description.md';
import SchemaSelector from './schema-selector/SchemaSelector';
import { EloomiSchema } from './resources/schemas';

function App() {
  const [schemaJson, setSchemaJson] = useState<any>(undefined);
  const [selectedSchema, setSelectedSchema] = useState<EloomiSchema | undefined>(undefined);

  useEffect(() => {
    setSchemaJson(undefined);
    if (selectedSchema)
      fetch(selectedSchema.schema)
        .then(async (response) => {
          const loadedSchema = await response.json();

          customizeSchema(loadedSchema);

          setSchemaJson(loadedSchema);
        })
  }, [selectedSchema])

  return (
    <div className="App">
      <SchemaSelector className="schema-selector" onChange={(s) => { setSelectedSchema(s) }} />
      <RedocStandalone spec={schemaJson} options={{
        theme: {
          spacing: {
            sectionVertical: 20
          },
          typography: {
            fontFamily: "Proxima Nova",
            headings: {
              fontFamily: "Proxima Nova",
            }
          }
        }
      }}/>
    </div>
  );
}

function customizeSchema(schema: any) {
  schema.info.description = apiDescription;
}

export default App;
